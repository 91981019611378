import React, { FC } from 'react';
import { DrawerEconomicOperator, MUIProvider } from '@next-common/mui';
import { renderReact } from '../../fragmentHelper/renderReactCustom';

import { hypernovaWrapperClassName } from '../../utils/hypernovaWrapperClassName';

const EconomicOperator: FC<{ title: string, eshop: boolean }> = ({ title, eshop }) => {
  return (
    <MUIProvider prefixwrapClassName={hypernovaWrapperClassName}>
      <DrawerEconomicOperator title={title} isEshop={eshop} />
    </MUIProvider>
  );
};

export const renderFunction = renderReact('EconomicOperator', EconomicOperator);

export const serverCanCache = true;